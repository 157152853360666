<template>
  <div class="layout-topbar p-justify-between">
    <div class="p-grid">
      <div class="p-grid p-lg-6 p-md-12">
        <div class="p-col-4 p-md-2">
          <router-link to="/">
            <img alt="Logo" width="70" height="30" :src="logo" />
          </router-link>
        </div>
        <div v-if="isLoggedIn" class="p-sm-12 p-md-10 p-xs-12">
          <div class="p-grid">
            <Menu
              ref="menuCreate"
              :model="createToolbarItems()"
              :popup="true"
            />
            <Button
              type="button"
              label="Создать заявку"
              class="p-mr-3 p-mt-2 p-xs-12"
              @click="toggleMenuCreate"
              v-if="permissions['create_task']"
            ></Button>
            <Menu ref="myTasks" :model="myTasksMenuItems" :popup="true" />
            <Button
              type="button"
              label="Мои заявки"
              icon="pi pi-angle-down"
              class="p-mr-3 p-mt-2 p-xs-12"
              @click="toggleMenuMyTasks"
            ></Button>
            <Menu
              ref="menuProfile"
              :model="profileToolbarItems()"
              :popup="true"
            />
            <Button
              type="button"
              label="Мои профили"
              icon="pi pi-angle-down"
              class="p-mt-2 p-mr-3 p-xs-12"
              @click="toggleMenuProfile"
            />
            <Menu
              ref="menuStatistic"
              :model="statisticToolbarItems()"
              :popup="true"
            />
            <Button
              type="button"
              label="Статистика"
              icon="pi pi-angle-down"
              class="p-mt-2 p-mr-3 p-xs-12"
              v-if="permissions['see_reports_button']"
              @click="toggleMenuStatistic"
            />
            <Menu
              ref="menuConstructor"
              :model="constructorToolbarItems()"
              :popup="true"
            />
            <Button
              icon="pi pi-angle-down"
              type="button"
              label="Конструкторы"
              class="p-mt-2 p-xs-12"
              @click="toggleMenuConstructor"
              v-if="permissions['checklist_maker']"
            />
          </div>
        </div>
      </div>
      <div v-if="isLoggedIn" class="p-grid p-lg-6 p-md-12" style="overflow: hidden">
        <div class="p-pt-lg-2 p-xs-4 p-md-4 menu-container">
          <span class="p-input-icon-left p-xs-12">
            <i class="pi pi-search" />
            <InputText
              id="filter"
              v-model.trim="filterText"
              placeholder="Номер заявки или ПТХ..."
              @keyup.enter="updateFilter"
              class="p-lg-11 p-md-11 p-xs-12"
            />
          </span>
        </div>
        <div v-if="role === 'EMPLOYEE_OF_CLIENT'" class="menu-container p-pt-lg-2 p-xs-4 p-md-4">
          <Dropdown
              v-model="selectedCompany"
              :options="companies"
              optionLabel="officialName"
              class="dropdown p-xs-12"
          />
        </div>
        <div v-if="role === 'OPERATOR' || role === 'HEAD_OF_SERVICE' || role === 'STELLAR_WARRANTY_ENGINEER'"
             class="menu-container p-pt-lg-2 p-xs-4 p-md-4">
          <MultiSelect
              v-model="selectedUserRegions"
              :options="userRegions"
              @change="saveSelectedUserRegions"
              optionLabel="name"
              placeholder="Регион"
              emptyFilterMessage="Совпадений не найдено"
              emptyMessage="Список регионов пуст"
              class="multiselect"
          />
        </div>
<!--        Ограничение для оператора РСМ-->
        <div v-if="myId !== 4035 && permissions['see_technic_producers']" class="p-pt-lg-2 p-xs-4 p-md-6 menu-container">
          <MultiSelect
              v-model="selectedTechnicProducers"
              :options="technicProducers"
              @change="saveSelectedTechnicProducers"
              :filter="true"
              optionLabel="name"
              class="multiselect"
              placeholder="Производитель техники"
              emptyFilterMessage="Совпадений не найдено"
              emptyMessage="Список производителей пуст"
          />
        </div>
        <div
          v-if="isLoggedIn"
          class="user p-md-4"
          style="align-self: center; margin-left: auto"
        >
          <div class="layout-topbar-icons p-text-lg-right p-text-md-right">
            <span>
              {{ roleText }} {{ userData.lastname }} {{ userData.firstname }}
            </span>
            <button class="p-link" id="logoutButton">
              <span
                class="layout-topbar-icon pi pi-user"
                @click="logout"
              ></span>
            </button>
            <i
              v-if="permissions['use_arrow']"
              @click="backArrow"
              class="pi pi-arrow-circle-left p-ml-4 arrow"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    header="Внимание!"
    v-model:visible="displayConfirmation"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>Вы уверены, что хотите выйти?</span>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        @click="closeConfirmation"
        class="p-button-text"
      />
      <Button
        label="Да"
        id="yesLogoutButton"
        icon="pi pi-check"
        @click="closeConfirmationAndLogout"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  props: ["layoutColorMode"],
  methods: {
    reloadMultiselect() {
      //Для перегрузки значений мультиселекта
      //через key не вышло
      this.selectedUserRegions = [];
      this.selectedUserRegions = this.$store.getters["selectedUserRegions"];
    },
    saveSelectedUserRegions() {
      this.$store.commit("setCurrentUserRegions", this.selectedUserRegions);
    },
    saveSelectedTechnicProducers() {
      this.$store.commit("setCurrentTechnicProducers", this.selectedTechnicProducers);
    },
    backArrow() {
      this.$router.go(-1);
    },
    toggleMenuCreate(event) {
      this.$router.push("/create_task");
    },
    toggleMenuProfile(event) {
      this.$refs.menuProfile.toggle(event, event.target);
    },
    toggleMenuStatistic(event) {
      this.$refs.menuStatistic.toggle(event, event.target);
    },
    toggleMenuConstructor(event) {
      this.$refs.menuConstructor.toggle(event, event.target);
    },
    toggleMenuMyTasks(event) {
      this.$refs.myTasks.toggle(event, event.target);
    },
    logout() {
      this.displayConfirmation = true;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    closeConfirmationAndLogout() {
      this.closeConfirmation();
      this.$store.dispatch("logout");
      this.$router.replace("/login");
    },
    updateFilter() {
      if (this.filterText) {
        this.$router.push("/tasks?search=" + this.filterText);
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Фильтр пуст",
          detail: "Пожалуйста, введите номер заявки",
          life: 6000,
        });
      }
    },
    createToolbarItems() {
      if (this.$store.getters.getRole === "ADMIN") {
        return [
          {
            label: "Профиль",
            icon: "pi pi-user-plus",
            to: "/create_user",
          },
        ];
      }

      if (this.$store.getters.getRole === "OPERATOR") {
        return [
          {
            label: "Заявку",
            icon: "pi pi-user-plus",
            to: "/create_task",
          },
          {
            label: "Клиента",
            icon: "pi pi-user-plus",
            to: "/create_client",
          },
        ];
      }

      if (this.$store.getters.getRole === "EMPLOYEE_OF_CLIENT") {
        return [
          {
            label: "Заявку",
            icon: "pi pi-user-plus",
            to: "/create_task",
          },
        ];
      }
    },
    constructorToolbarItems() {
      return [
        {
          label: "Чеклисты",
          to: "/checklist_maker",
        },
        {
          label: "Техника",
          to: "/technic_maker",
        },
      ];
    },
    profileToolbarItems() {
      const menu = [
        {
          label: "Мой профиль",
          icon: "pi pi-user",
          to: "/profile",
        },
      ];
      if (this.permissions["users"]) {
        menu.push({
          label: "Сотрудники",
          icon: "pi pi-users",
          to: "/users",
        });
      }

      if (this.permissions["see_clients_button"]) {
        menu.push({
          label: "Клиенты",
          icon: "pi pi-money-bill",
          to: "/clients",
        });
      }

      return menu;
    },
    statisticToolbarItems() {
      let menu = [];

      if (this.permissions["see_reports_about_engineers"]) {
        menu = [
          ...menu,
          {
            label: "Отчёты по инженерам",
            icon: "pi pi-file",
            to: "/reports",
          },
          {
            label: "Отчёт по одному инженеру",
            icon: "pi pi-file",
            to: "/engineerReport",
          },
          {
            label: "Отчёт по запчастям",
            icon: "pi pi-cog",
            to: "/unusedDetails"
          },
        ];
      }

      if (this.permissions["see_report_about_me"]) {
        const date = new Date(
          new Date().setMonth(new Date().getMonth() - 1)
        ).toISOString();
        menu = [
          ...menu,
          {
            label: "Обо мне",
            icon: "pi pi-file",
            to: `/aboutMe?engineerId=${
              this.myId
            }&dateStart=${date}&dateEnd=${new Date().toISOString()}`,
          },
        ];
      }

      if (this.permissions["see_attendance_log"]) {
        menu = [
          ...menu,
          {
            label: "Журнал посещаемости",
            icon: "pi pi-users",
            to: "/attendance",
          },
        ];
      }

      return menu;
    },
    //TODO Привести в порядок меню заявок могут выводиться по три
    myTasksItems() {
      if (!this.config.pages) return;
      const menu = [];
      for (const btn of this.config.pages) {
        let urlQuery = "";
        for (const key in btn) {
          if (typeof btn[key] === "object") {
            for (const item of btn[key]) {
              urlQuery += `&${key}=${item}`;
            }
          } else {
            urlQuery += `&${key}=${btn[key]}`;
          }
        }

        menu.push({
          icon: btn.id ? "pi pi-heart" : "",
          label: btn.name,
          to: `/tasks?${urlQuery}`,
        });
      }

      this.myTasksMenuItems = [...menu];
    },
    //TODO Переделать под vuex storage
    getSelectCompanyFromLocalStorage() {
      //Такая штука, чтобы не менялся текущая комания у работника при обовлении страницы
      //TODO Переделать под Vuex
      if (localStorage.getItem("employeeCurrentCompany") !== "undefined") {
        this.selectedCompany = JSON.parse(
          localStorage.getItem("employeeCurrentCompany")
        );
      }
    },
  },
  async mounted() {
    window.addEventListener("clearTopBarFilter", () => {
      this.filterText = null;
    });
    window.addEventListener(
      "reloadMultiselectAfterPickRegions",
      this.reloadMultiselect
    );
    this.getSelectCompanyFromLocalStorage();
    this.selectedUserRegions = this.$store.getters.selectedUserRegions;
    this.selectedTechnicProducers = this.$store.getters.selectedTechnicProducers;
  },
  async unmounted() {
    window.removeEventListener("clearTopBarFilter", () => {
      this.filterText = null;
    });
    window.removeEventListener(
      "reloadMultiselectAfterPickRegions",
      this.reloadMultiselect
    );
  },
  data() {
    return {
      myTasksMenuItems: [],
      displayConfirmation: false,
      filterText: null,
      selectedCompany: null,
      selectedUserRegions: [],
      selectedTechnicProducers: [],
    };
  },
  watch: {
    config: function () {
      this.myTasksItems();
    },
    selectedCompany: function (newValue, oldValue) {
      if (oldValue !== null) {
        window.dispatchEvent(new CustomEvent("refreshEmployeeCompanySet"));
      }
      let employeeCurrentCompany = JSON.stringify(newValue);
      localStorage.setItem("employeeCurrentCompany", employeeCurrentCompany);
    },
    isLoggedIn: function () {
      this.getSelectCompanyFromLocalStorage();
    },
    companies: function () {
      if (this.selectedCompany === null && this.companies) {
        if (this.role === "EMPLOYEE_OF_CLIENT") {
          this.selectedCompany = this?.companies[0];
        }
      }
    },
  },
  computed: {
    config() {
      return this.$store.getters["data/config"];
    },
    userRegions() {
      return this.$store.getters.userRegions;
    },
    technicProducers() {
      return this.$store.getters["data/technicProducers"];
    },
    myId() {
      return this.$store.getters.getId;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    logo() {
      return this.layoutColorMode === "dark"
        ? "/assets/layout/images/logo.png"
        : "/assets/layout/images/logo.png";
    },
    roleText() {
      return this.$store.getters.getRoleText;
    },
    userData() {
      return this.$store.getters.userData;
    },
    companies() {
      return this.userData.companies;
    },
    role() {
      return this.$store.getters.getRole;
    },
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

<style scoped>
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  box-sizing: border-box;
  width: 25%;
  min-width: 187px;
}
.arrow {
  font-size: 20px;
  cursor: pointer;
}
.user {
  width: 25%;
  min-width: 187px;
}
.multiselect {
  min-width: 187px;
  width: 25%;
  margin-right: 20px;
  color: black;
}
.menu-container {
  width: 25%;
  min-width: 187px;
}

@media screen and (max-device-width: 580px) {
  .menu-container {
    width: 100%;
  }
  .dropdown {
    width: 100%;
  }
  .multiselect {
    width: 100%;
  }
  .user {
    width: 100%;
    margin-top: 7px;
  }
}
</style>
